window.StimulusApplication.register(
  'datepicker',
  class extends Stimulus.Controller {
    static targets = ['start', 'end'];

    connect() {
      let today = new Date();
      let startDate;
      if (today.getDate() < 15) {
        startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      } else {
        startDate = new Date(today.getFullYear(), today.getMonth() + 2, 1);
      }

      $(this.startTarget)
        .datepicker({
          format: 'dd-mm-yyyy',
          viewMode: 'months',
          minViewMode: 'months',
          startDate: startDate,
          endDate: new Date(
            new Date().setFullYear(new Date().getFullYear() + 30),
          ),
        })
        .on('changeDate', (e) => {
          $(this.endTarget).val('');
          let newStartDate = new Date(e.date);
          newStartDate.setFullYear(newStartDate.getFullYear() + 5);
          $(this.endTarget).datepicker('setStartDate', newStartDate);
        });

      $(this.endTarget).datepicker({
        format: 'dd-mm-yyyy',
        viewMode: 'months',
        minViewMode: 'months',
        endDate: new Date(
          new Date().setFullYear(new Date().getFullYear() + 30),
        ),
      });
    }
  },
);
