Turbo.session.drive = false;
window.StimulusApplication = Stimulus.Application.start();

document.addEventListener('DOMContentLoaded', function () {
  !(function ($) {
    'use strict';

    var App = function () {
      (this.$body = $('body')), (this.$window = $(window));
    };

    App.prototype.initAccountSelection = function () {
      var $this = this;

      // account dropdown
      $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
        if (!$(this).next().hasClass('show')) {
          $(this)
            .parents('.dropdown-menu')
            .first()
            .find('.show')
            .removeClass('show');
        }
        var $subMenu = $(this).next('.dropdown-menu');
        $subMenu.toggleClass('show');

        return false;
      });

      $('#accountSelectionForm a.submit-link').on({
        click: function (event) {
          event.preventDefault();
          var accountId = $(this).data().accountId;
          $('#accountSelectionForm input[name="account_id"]').val(accountId);
          $(this).closest('form').submit();
        },
      });
    };

    App.prototype.requestConfirmWithdrawals = function () {
      $(document).on('submit', '#request-withdrawal-form', function (e) {
        e.preventDefault();

        var form_data = {};
        $.each($(this).serializeArray(), function (_, kv) {
          form_data[kv.name] = kv.value;
        });

        $('#withdrawal-request-amount').text('€ ' + form_data.amount);
        $('#withdrawal-request-date').text(
          $('#id_withdrawal_date option:selected').text(),
        );

        $('#withdraw-confirmation-modal').modal('show');

        $('#withdrawal-request-confirm-btn').on('click', function (e) {
          document.getElementById('request-withdrawal-form').submit();
        });
      });
    };

    App.prototype.requestIncassoExcasso = function () {
      $(document).on('submit', '#incasso-excasso-request-form', function (e) {
        e.preventDefault();

        var form_data = {};
        $.each($(this).serializeArray(), function (_, kv) {
          form_data[kv.name] = kv.value;
        });

        $('#incasso-excasso-request-amount').text('€ ' + form_data.amount);

        $('#request-incasso-excasso-confirmation-modal').modal('show');

        $('#incasso-excasso-request-confirm-btn').on('click', function (e) {
          document.getElementById('incasso-excasso-request-form').submit();
        });
      });
    };

    App.prototype.requestExcassoCancel = function () {
      $(document).on('submit', '#excasso-cancel-form', function (e) {
        e.preventDefault();

        var form_data = {};
        $.each($(this).serializeArray(), function (_, kv) {
          form_data[kv.name] = kv.value;
        });

        $('#request-incasso-excasso-cancel-confirmation-modal').modal('show');

        $('#incasso-excasso-cancel-request-confirm-btn').on(
          'click',
          function (e) {
            document.getElementById('excasso-cancel-form').submit();
          },
        );
      });
    };

    App.prototype.requestIncassoCancel = function () {
      $(document).on('submit', '#incasso-cancel-form', function (e) {
        e.preventDefault();

        var form_data = {};
        $.each($(this).serializeArray(), function (_, kv) {
          form_data[kv.name] = kv.value;
        });

        $('#request-incasso-excasso-cancel-confirmation-modal').modal('show');

        $('#incasso-excasso-cancel-request-confirm-btn').on(
          'click',
          function (e) {
            document.getElementById('incasso-cancel-form').submit();
          },
        );
      });
    };

    App.prototype.initDatePicker = function () {
      var lang = $('html').attr('lang');
      moment.locale(lang);

      // date pickers
      $('[data-toggle="date-picker"]').each(function (idx, obj) {
        var defaultOptions = {
          cancelClass: 'btn-',
          applyButtonClasses: 'btn btn-primary',
          singleDatePicker: true,
          showDropdowns: true,
          maxYear: new Date().getFullYear(),
          maxDate: moment().subtract(1, 'day').endOf('day'),
          locale: {
            format: 'DD-MM-YYYY',
            cancelLabel: 'Annuleer',
            applyLabel: 'Pas toe',
          },
          autoUpdateInput: false,
        };
        var objOptions = $.extend({}, defaultOptions, $(obj).data());
        $(obj).daterangepicker(objOptions, function (chosen_date) {
          $(obj).val(chosen_date.format('DD-MM-YYYY'));
        });
      });

      $('#id_range').change(function (e) {
        var value = e.target.value;
        handleFilterVisibility(value);
        if (value !== 'custom') {
          var format = 'DD-MM-YYYY';
          var startDate, endDate;
          var today = moment().format(format);
          if (value === 'ytd') {
            startDate = moment().startOf('year').format(format);
            endDate = today;
          } else if (value === 'mtd') {
            startDate = moment().startOf('month').format(format);
            endDate = today;
          } else if (value === 'qtd') {
            startDate = moment().startOf('quarter').format(format);
            endDate = today;
          } else if (value === 'last-year') {
            startDate = moment()
              .subtract(1, 'year')
              .startOf('year')
              .format(format);
            endDate = moment().subtract(1, 'year').endOf('year').format(format);
          } else if (value === 'all') {
            startDate = $('.portfolio-overview-box').data().inceptionDate;
            endDate = today;
          }
          $('#id_start_date').val(startDate);
          $('#id_end_date').val(endDate);
          $('#filter-form').submit();
        }
      });

      function handleFilterVisibility(value) {
        if (value === 'custom') {
          $('#rangepicker').removeClass('d-none');
        } else {
          $('#rangepicker').addClass('d-none');
        }
      }
      handleFilterVisibility($('#id_range').val());
    };

    App.prototype.passwordMeter = function () {
      var password = document.getElementById('id_password1');
      var meter = document.getElementById('password-strength-meter');

      if (password !== null) {
        password.addEventListener('keypress', function () {
          var val = password.value;
          var result = zxcvbn(val);
          meter.value = result.score;
        });
      }
    };

    App.prototype.initTooltip = function () {
      $('[data-toggle="tooltip"]').tooltip();
    };

    //initilizing
    App.prototype.init = function () {
      var $this = this;
      this.initAccountSelection();
      this.requestConfirmWithdrawals();
      this.requestIncassoExcasso();
      this.requestIncassoCancel();
      this.requestExcassoCancel();
      this.passwordMeter();
      this.initDatePicker();
      this.initTooltip();
    };

    $.App = new App();
    $.App.Constructor = App;
  })(window.jQuery),
    //initializing main application module
    (function ($) {
      'use strict';
      $.App.init();
    })(window.jQuery);
});
